import Layout from '../components/layout';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { changeTransition } from '../webservices/ops-event-api';
import { navigate } from 'gatsby';
import { OpsEventContext } from '../ops-event.context';
import styles from './cancel.module.css';

function Cancel({ location }) {
  const { eventType } = useContext(OpsEventContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (
      !params.get('event-id') ||
      !params.get('candidate-id') ||
      !params.get('token')
    ) {
      navigate('/404');
      return;
    }

    changeTransition(
      params.get('candidate-id'),
      params.get('event-id'),
      'refused',
      params.get('token')
    )
      .then(() => setIsLoading(false))
      .catch(() => {
        navigate('/error');
      });
  }, []);

  const is_e_job_dating = eventType && eventType.id === 'e_job_dating';

  const titleWording = is_e_job_dating
    ? 'Votre annulation a bien été prise en compte'
    : 'Merci pour votre intérêt.';
  const paragraphWording = is_e_job_dating
    ? `N'hésitez pas à consulter nos autres événements de recrutement.`
    : `Votre non participation à l'événement ${
        eventType ? eventType.name : ''
      } a bien été enregistrée.`;

  return (
    <Layout location={location}>
      {!isLoading ? (
        <div>
          <h1 className={styles.title}>{titleWording}</h1>
          <p>{paragraphWording}</p>
        </div>
      ) : (
        <h1 className={styles.loadingTitle}>Chargement...</h1>
      )}
    </Layout>
  );
}

Cancel.propTypes = {
  location: PropTypes.object,
};

export default Cancel;
